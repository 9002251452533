import React, { Component } from "react";
import "../page.css";
import AboutData from "../../../data/AdminAbout.json";
import LinkedinIcon from "../../../static/icons/linkedin.svg";

class About extends Component {
  render() {
    return (
      <div className="page">
        <section className="page-image-section">
          <div className="page-image-wrapper">
            <img
              className="page-image"
              alt={AboutData.title}
              src={AboutData.image}
            />
            <h1 className="page-title">{AboutData.title}</h1>
          </div>
        </section>
        <article
          dangerouslySetInnerHTML={{ __html: AboutData.desc }}
          className="page-article"
        />
        <div className="container">
          <div className="page-sub-section">
            <h2 className="heading">Kurucu</h2>
            <div className="headingLine" />

            <div className="users">
              <div className="userWrapper">
                <img
                  className="user-image"
                  src="https://res.cloudinary.com/dnnrexpjo/image/upload/v1601110153/ugur.jpg"
                  alt="ugur"
                />
                <div className="user-info">
                  <div className="user-info-detail">
                    <h2 className='user-name'>Av. Uğur Büyükyılmaz</h2>
                    <div className="user-social">
                      <a href="http://linkedin.com/in/uğur-büyükyılmaz-ll-m-0b527a95" title="Avukat Ugur Büyükyılmaz Linkedin">
                        <img src={LinkedinIcon} alt="linkedin" />
                      </a>
                    </div>
                  </div>
                  <text className='user-title'>Kurucu ve Yönetici Avukat</text>
                  <p>Marmara Üniversitesi, Hukuk Fakültesi</p>
                  <p>
                    Bahçeşehir Üniversitesi, Hukuk Fakültesi Sermaye Piyasaları
                    ve Ticaret Hukuku Yüksek Lisansı (LL.M.)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
